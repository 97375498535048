@import '../utilities/mixins';
@import '../utilities/functions';
@import '../variables/color';

body {
  width: 100%;
  height: 100%;
  position: relative;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
html {
  font-size: 16px;
}

div .zero-margin {
  margin: 0;
}
.small-content-wrap {
  max-width: 720px;
  margin: 100px auto;
  height: fit-content;
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
  }
  #b-6 {
    border-radius: 6px 6px 0 0;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #4f9be3;
  }
}
.core-content-wrap {
  width: 100%;
  max-width: 1200px; //1440px with 24px gutters = 1392px
  margin: pxToRem(64, 0);
  &.files {
    margin-top: 0;
  }
  &.full-width {
    max-width: none;
  }
  .core-content {
    padding: pxToRem(0, 12);
  }
}

#core-content-container {
  flex: 1;
}

.paper-container {
  position: relative;
  padding-bottom: pxToRem(93);
}
.full-width {
  width: 100%;
}
/* Dynamically Hide and Show Components*/
.hide {
  display: none;
}

/*Disabled Class*/
.disabled-btn {
  cursor: pointer;
  color: #bdbdbd;
  background-color: #e0e0e0;
  cursor: 'pointer';
}

.custom-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $status-error; // red is the default. Can be extended.
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  @extend .dot;
  background-color: $status-open;
}

.pending-dot {
  @extend .dot;
  background-color: $status-pending;
}

.pending-status {
  color: $status-pending;
}

.grey-dot {
  @extend .dot;
  background-color: $status-cancelled;
}

.blue-dot {
  @extend .dot;
  background-color: $status-closed;
}

.error-status {
  color: $status-error;
}

.t-u {
  text-decoration: underline;
}
.open-status {
  color: $status-open;
}

.cancelled-status {
  color: $status-cancelled;
}

.closed {
  color: $status-closed;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.tooltip-grid {
  background-color: #fff !important;
  color: #423d3d !important;
  font-size: 13px !important;
  padding: 5px !important;
  white-space: nowrap !important;
  font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif !important;
  border: 1px solid lightgrey !important;
}
