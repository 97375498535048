@import '../../../common-assets/styles/utilities/functions';

.searchFieldsWrap {
  padding-top: pxToRem(30);
  margin-top: 0;
}

.form-control-label {
  padding-top: pxToRem(15);
}

.backtitle-search-content,
.backtitle-search-result {
  padding: pxToRem(0, 24, 24);
}

.MuiSvgIcon-root {
  &.no-backtitles {
    display: block;
    margin: 0 auto;
    width: pxToRem(140);
    height: pxToRem(180);
  }
}

.no-results-msg {
  display: block;
  margin: 0px auto;
  padding: 40px;
  h3,
  p {
    display: block;
    text-align: center;
  }
  h3 {
    margin: 16px 0;
  }
  p {
    margin: 0px;
  }
}

.back-title-grid {
  height: 100%;
  width: 100%;

  .ag-root-wrapper {
    position: relative;
    overflow: visible;
  }
  .ag-dialog.ag-ltr.ag-popup-child {
    top: 0 !important;
  }
  .ag-header {
    transition: all 0.2s ease;
    &.fixed-header {
      position: fixed;
      top: 121px;
      z-index: 5;
      left: auto;
    }
  }

  .ag-theme-alpine .ag-header-fixed {
    padding-top: var(--ag-header-height);
  }
}

.backtitle-header {
  position: relative;
  width: 100%;
}
.loading-container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.documentName {
  color: #0054a4;
  text-decoration: underline;
  cursor: pointer;
}

.p-b-25 {
  padding-bottom: pxToRem(25) !important;
}
